import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { helpToggle } from './help.js';
import { aboutScript } from './about.js';
import { initHomeMap } from './multiMap.js';
import { unitFilters, facilityTabs, facilityCaro, facilityMap, facilityTop } from './facility.js';
import { initReviewsCaro } from './reviews.js';
import { feedbackPage } from './feedback.js';
import { homeCaro } from './home.js';

navToggle.init();

helpToggle.init();

aboutScript.init();

unitFilters.init();

facilityTabs.init();
facilityCaro.init();
facilityMap.init();

homeCaro.init();

if(document.getElementById('top_button')){
	facilityTop.init();
}

if(document.getElementById('reviews_page')){
	feedbackPage.init();
}

if (document.getElementById('contact_form')) {
	if (document.getElementById('facility_coming_soon')) {
		contactSubmit({
			required: ['name','email']
		});
	} else {
		contactSubmit({
			required: ['name', 'facility', 'email', 'message']
		});
	}
}

if(document.querySelector('#inc_map')) {
	initHomeMap();
}

if(document.querySelector('#reviews')) {
	initReviewsCaro();
}